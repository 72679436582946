<template>
  <!-- DATA - RIGHT SCROLLBAR -->
  <!-- :max-height="$vuetify.breakpoint.smAndDown ? 110 : 630" -->
  <!-- :height="$vuetify.breakpoint.smAndDown ? `730` : `630`" -->
  <v-card
    elevation="0"
    color="#1a1b1d"
    style="margin-left: 5px"
    min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`"
    :style="
      $vuetify.breakpoint.smAndDown
        ? `border-left: `
        : `border-left:1px solid gray`
    "
  >
    <v-card-text>
      <div>
        <div class="">
          <!-- :style="{ 'text-align:center;': $vuetify.breakpoint.smAndDown }" -->
          <h2 class="HeadTextStyle" :style="fontSize">Contact</h2>
          <!-- <p class="desc-1"></p> -->
        </div>

        <div class="section myservices">
          <div class="container" style="padding-top: 30px">
            <div class="phone box">
              <div class="row headRow mx-0 mb-3">
                <i class="greenicon fas fa-phone-alt pr-3"></i>
                <p class="headText" style="border-bottom: none">Phone</p>
              </div>
              <p class="desc-1">+381 (0) 65 222 88 68</p>
            </div>
            <div class="email box">
              <div class="row headRow mx-0 mb-3">
                <i class="greenicon far fa-envelope pr-3"></i>
                <p class="headText" style="border-bottom: none">E-mail</p>
              </div>
              <p class="desc-1">vasicigorjsp@gmail.com</p>
            </div>
            <div class="location box">
              <div class="row headRow mx-0 mb-3">
                <i class="greenicon fas fa-thumbtack pr-3"></i>
                <p class="headText" style="border-bottom: none">Location</p>
              </div>
              <p class="desc-1">
                <span class="textInsideProject">Country: </span> Serbia
                <br />
                <span class="textInsideProject">City: </span> Belgrade
                <br />
                <span class="textInsideProject">Postal code: </span> 11000
              </p>
            </div>
            <!-- <div class="social_media box">
              <i class="greenicon fas fa-hashtag"></i>
              <p class="headText">Social media</p>
              <p class="desc-1">
                Or you can use some of my social media icons to contact me
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return { "font-size": "1.35em !important" };
        default:
          return { "font-size": "1.4em" };
      }
    },
  },
};
</script>

<style></style>
